figure.testimonialCard {
  /* font-family: ; */
  position: relative;
  overflow: hidden;
  margin: 0px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}
figure.testimonialCard * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.testimonialCard img {
  max-width: 100%;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  display: inline-block;
  z-index: 1;
  position: relative;
}
figure.testimonialCard blockquote {
  margin: 0;
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 30px 50px 65px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0 0 -50px;
  line-height: 1.6em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
figure.testimonialCard blockquote:before,
figure.testimonialCard blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.testimonialCard blockquote:before {
  top: 35px;
  left: 20px;
}
figure.testimonialCard blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 35px;
}
figure.testimonialCard .author {
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}
figure.testimonialCard .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure.testimonialCard .author h5 span {
  font-weight: 400;
  text-transform: none;
  display: block;
}
